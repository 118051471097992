@import "../../../../../../node_modules/@causevest/ui-kit/variables";

.stub {
  margin-top: -75px;
  height: 151px;
  border-radius: 4px;
}

.topBar {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 26px;
  margin-top: -75px;
  padding: 20px;
  background: $color-white;
  border-radius: 4px;
  box-shadow: 0 10px 20px 0 rgba(5, 29, 60, 0.03);

  &__title {
    color: $color-black-6;
    font-size: 20px;
    font-weight: 500;
    margin: 0;
  }

  &__headingInner {
    gap: 10px;
  }

  &__switcher {
    flex-direction: row;
    align-items: center;
    gap: 15px;
  }

  &__right {
    justify-content: space-between;
    gap: 10px;
  }

  &__btns {
    flex-direction: row;
    gap: 15px;
  }
}

.avatarSection {
  &._mobile {
    display: none;
  }
}

.shareBtn {
  width: 100px;
  height: 35px;

  &__icon {
    height: 17px;
  }
}

.donateBtn {
  width: 135px;
  height: 35px;
}

.editBtn {
  width: 125px;
  height: 32px;
  background: transparent;

  &__icon {
    height: 17px;
  }
}

.perks {
  gap: 54px;

  &__wrapper {
    flex-grow: 2;
    flex-direction: row;
    align-items: flex-end;
    gap: 60px;
  }

  &__item {
    text-align: center;
    max-width: 75px;
    align-items: center;
  }

  &__divider {
    width: 2px;
    height: 32px;
    background: $color-light-grey;
  }

  &__title {
    color: $color-grey-7;
    font-size: 24px;
    font-weight: 300;
  }

  &__hint {
    font-size: 14px;
    color: $color-grey-7;
    font-weight: 400;
    white-space: nowrap;
  }
}

@media (max-width: $lg) {
  .topBar {
    &__headingInner {
      flex-direction: row;
      align-items: center;
    }

    &__right {
      gap: 20px;
    }
  }

  .perks {
    margin-left: 20px;

    &__wrapper {
      flex-direction: column;
      align-items: flex-start;
      gap: 15px;
    }
  }
}

@media (max-width: $md) {
  .perks {
    display: none;
  }
}

@media (max-width: 920px) {
  .avatarSection {
    &._mobile {
      display: block;
    }

    &._desktop {
      display: none;
    }
  }

  .topBar {
    &__heading {
      flex-direction: row;
      align-items: center;
      gap: 15px;
    }

    &__headingInner {
      flex-direction: column;
      align-items: flex-start;
    }

    &__right {
      gap: 14px;
      margin-top: 0;
    }
  }
}

@media (max-width: 650px) {
  .topBar {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    &__title {
      overflow: hidden;
      @include textEllipsis(48vw);
    }

    &__right {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }
}

@media (max-width: 450px) {
  .topBar__switcher {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .donateBtn {
    width: 100px;
  }
}
