@import "../../../../../../../node_modules/@causevest/ui-kit/variables";

.wrapper {
  flex-direction: row;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.divider {
  width: 2px;
  min-width: 2px;
  height: 32px;
  background: $color-light-grey;
  margin: 0 45px;
}

.count {
  font-size: 24px;
  font-weight: 300;
}

@media (max-width: $sm) {
  .divider {
    margin: 0 25px;
  }

  .title {
    font-size: 14px;
  }

  .title {
    white-space: nowrap;
  }
}

@media (max-width: 590px) {
  .bg {
    display: none;
  }
}

@media (max-width: 560px) {
  .wrapper {
    overflow-x: auto;
  }
}
