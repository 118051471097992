@import "@causevest/ui-kit/variables";

.btn {
  min-width: 20px !important;
  border: 1px solid $color-light-grey;

  &[disabled] {
    cursor: default;

    > svg {
      color: $color-light-grey;
    }

    &:hover {
      background: $color-white;
    }
  }

  > svg {
    color: $color-blue;
    font-size: 16px;
  }

  &._prev {
    border-radius: 4px 0 0 4px;
    border-right: none;
  }

  &._next {
    border-radius: 0 4px 4px 0;
    margin-left: -1px;
  }

  &._big {
    > svg {
      font-size: 20px;
    }
  }
}
