@import "../../../../../node_modules/@causevest/ui-kit/variables";

.wrapper {
  scroll-margin-top: 150px;
}

.heading {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.title {
  background: $color-grey-5;
  padding: 15px 20px;
  margin-left: -20px;
  color: $color-black-6;
  font-size: 27px;
  font-weight: 400;
  letter-spacing: 1.35px;
  width: 100%;

  &._light {
    background: transparent;
    margin: 0;
    padding: 0;
    width: auto;
  }
}

.feedInner {
  margin-top: 20px;
  margin-bottom: 20px;
}

@media (max-width: $sm) {
  .heading {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 15px;
  }

  .title {
    margin-left: -20px;
    padding: 20px;
  }
}
