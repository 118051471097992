@import "../../../../../../node_modules/@causevest/ui-kit/variables";

.wrapper {
  padding: 32px 32px 64px;
}

.inner {
  gap: 40px;
}

.title {
  font-size: 20px;
  font-weight: 300;
  text-align: center;
}

.image {
  margin: 0 auto;
  width: 100%;
  height: 284px;
}