@import "@causevest/ui-kit/variables";

.asterisk {
  color: $color-red;
}

.select {
  > * {
    border-radius: 4px 0 0 4px;
  }

  [role="combobox"] {
    padding: 10px 30px 10px 15px;
  }

  fieldset {
    border-right-color: transparent;
  }
}

.fullWidth {
  width: 100%;
}

.input {
  padding-left: 0;
  margin-left: -1px;
  width: calc(100% + 1px);

  > * {
    border-radius: 0 4px 4px 0;
    padding-left: 0;
  }

  input {
    padding: 10px 15px;
  }
}
