@import "@causevest/ui-kit/variables";

.wrapper {
  position: relative;
}

.input {
  > * {
    width: 250px;
    height: 36px;
    padding: 2px 4px 2px 10px;
    font-size: 14px;
    border-radius: 4px;
    background: $color-bg-blue;

    input {
      padding: 0;
      height: 100%;
    }

    fieldset {
      border-color: transparent !important;
    }

    &:hover {
      fieldset {
        border-color: transparent !important;
      }
    }
  }

  &._active {
    > * {
      width: 450px;
    }
  }

  &._long {
    > * {
      width: 300px;

      input {
        width: 100%;
      }
    }
  }

  img {
    margin-top: 5px;
  }
}

.submitBtn {
  position: absolute;
  right: 4px;
  top: 4px;
  background: transparent !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 13px;
}
