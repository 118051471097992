@import "@causevest/ui-kit/variables";

.wrapper {
  align-items: center;
  opacity: 0.8;
  gap: 15px;
}

.socialItem {
  svg {
    color: $color-grey-2;
    width: 17px;
    height: 17px;
    transition: color 0.2s ease-in-out;
  }

  &:hover {
    svg {
      color: $color-blue;
      animation: bounceIn 1s;
    }
  }

  &._footer {
    display: flex;
    width: 50px;
    height: 50px;
    border-radius: 50%;

    svg {
      width: 25px;
      height: 25px;
      margin: auto;
      color: $color-white;
    }

    &:first-child {
      background: #2ca5e0;
    }

    &:nth-child(2) {
      background: #7289d9;
    }

    &:nth-child(3) {
      background: #55acee;
    }

    &:nth-child(4) {
      background: #dd4b39;
    }
  }
}

@keyframes bounceIn {
  0%,
  20%,
  40%,
  60%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity: 1;
    transform: scaleX(1);
  }
}
