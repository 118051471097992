@import "../../../../../../../node_modules/@causevest/ui-kit/variables";

.wrapper {
  gap: 30px;
}

.inner {
  flex-direction: row;
  gap: 30px;
}

.right {
  gap: 15px;
}

.heading {
  gap: 25px;
}

.mobileUserInfo {
  display: none;
}

.title {
  font-size: 24px;
  font-weight: 500;
}

.info {
  flex-direction: row;
  gap: 40px;
}

.description {
  font-size: 14px;
  word-break: break-word;
  white-space: pre-line;
  @include multilineEllipsis(5, 100%);
}

.contacts {
  gap: 15px;
}

.footer {
  gap: 15px;

  &__left {
    gap: 15px;
  }

  &__btn {
    width: 126px;
    height: 35px;
  }
}

@media (max-width: $md) {
  .heading {
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
  }

  .info {
    flex-direction: column;
    gap: 20px;
  }

  .contacts {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px 15px;

    > * {
      white-space: nowrap;
    }
  }
}

@media (max-width: $sm) {
  .footer {
    &__left {
      width: 100%;
      justify-content: space-between;
    }

    &__btn {
      &._support {
        display: none;
      }
    }
  }
}

@media (max-width: 485px) {
  .desktopUserInfo {
    display: none;
  }

  .mobileUserInfo {
    display: block;
  }

  .inner {
    flex-direction: column;
    gap: 20px;
  }

  .footer {
    flex-direction: column;
    gap: 10px;

    &__left {
      flex-direction: column;
      gap: 10px;
    }

    &__btn {
      width: 100%;
    }
  }
}
