@import "../../../../../../node_modules/@causevest/ui-kit/variables";

.wrapper {
  gap: 14px;
  margin-bottom: 30px;
}

.item {
  gap: 10px;
  text-decoration: none;

  &__image {
    min-width: 60px;
    height: 60px;
    border-radius: 4px;
    overflow: hidden;
  }

  &__title {
    color: $color-grey-7;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
  }

  &__view {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: $color-blue;
    font-weight: 500;
    font-size: 12px;
    white-space: nowrap;
    margin-top: 5px;

    > svg {
      font-size: 15px;
    }
  }
}
