@import "@causevest/ui-kit/variables";

.footer {
  width: 100%;
  padding: 16px 24px;
  border-top: 1px solid $color-light-grey;

  &__prevBtn {
    background: transparent;
  }

  &__submitBtn {
    padding: 10px 30px;
  }
}