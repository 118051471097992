@import "@causevest/ui-kit/variables";

.empty {
  gap: 20px;
  margin-top: 30px;

  &__title {
    color: $color-grey-7;
    text-align: center;
    font-size: 18px;
    font-weight: 300;
    line-height: 25px;
  }
}

.wrapper {
  gap: 15px;
}

.inner {
  gap: 10px;
  width: 135px;
}

.title {
  color: $color-grey-7;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;

  &._declined {
    color: $color-red;
  }
}

.by {
  color: $color-grey-1;
  font-size: 11px;
  font-weight: 400;
}

.time {
  max-width: 25%;
  color: $color-grey-1;
  font-size: 11px;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  @include textEllipsis(25%);
}

@media (max-width: $sm) {
  .inner {
    gap: 10px;
    width: 70%;
  }
}
