@import "../../../../../../../../node_modules/@causevest/ui-kit/variables";

.wrapper {
  gap: 15px;
}

.person {
  gap: 10px;

  &__icon {
    width: 37px;
    height: 37px;
    border-radius: 50%;
  }

  &__name {
    color: $color-grey-7;
    font-size: 14px;
    font-weight: 500;
  }

  &__time {
    color: $color-grey-1;
    font-size: 10px;
    font-weight: 500;
  }
}

.title {
  font-size: 18px;
  font-weight: 500;
  color: $color-black-6;
}

.content {
  color: $color-grey-7;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;

  > p {
    margin: 0;
  }
}

.bottom {
  gap: 25px;

  &__btn {
    display: flex;
    align-items: center;
    font-weight: 500 !important;
    gap: 5px;
    font-size: 10px !important;
    text-decoration: underline;
    color: $color-grey-1;
    white-space: nowrap;
    padding: 0;
    transition: all 0.3s ease-in-out;

    &:hover {
      background: transparent;
      color: $color-blue;
    }
  }

  &__icon {
    height: 10px;
  }
}

.subcomments {
  overflow: hidden;
  padding-left: 30px;
  gap: 15px;

  &._share {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 0;
  }
}

.closeBtn {
  &:hover {
    background: transparent;
  }
}

.subcomment {
  flex-direction: row;
  align-items: center;
  gap: 30px;

  &__person {
    gap: 10px;
    width: 110px;
    min-width: 110px;
  }

  &__name {
    font-size: 12px;
  }

  &__time {
    white-space: nowrap;
    font-size: 10px;
    color: $color-grey-1;
  }
}

@media (max-width: 1220px) {
  .subcomments {
    padding-left: 20px;
  }

  .creationForm__inner {
    width: 100%;
    margin-left: 20px;
  }
}

@media (max-width: $md) {
  .subcomments {
    margin-top: 10px;
    margin-bottom: 5px;
  }

  .subcomment {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;

    &__person {
      width: auto;
    }
  }

  .bottom {
    flex-wrap: wrap;
  }
}
