@import "../../../../../../node_modules/@causevest/ui-kit/variables";

.heading {
  font-size: 24px;
  margin: 0;
  padding: 24px;
  border-bottom: 1px solid $color-light-grey;
  font-weight: 300;
}

.wrapper {
  gap: 20px;
  min-width: 600px;
  margin-top: 20px;
}

.row {
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 30px;

  > * {
    width: 50%;
  }

  &__inner {
    gap: 20px;
  }
}

@media (max-width: $sm) {
  .heading {
    font-size: 18px;
    padding: 16px 24px;
  }

  .wrapper {
    min-width: auto;
  }

  .row {
    flex-direction: column;

    > * {
      width: 100%;
    }
  }
}
