@import "@causevest/ui-kit/variables";

.menuBtn {
  cursor: pointer;
  padding: 0 3px 0 0;
  gap: 8px;

  &__text {
    font-size: 14px;
    font-weight: 400;
    color: $color-black;
    @include textEllipsis(95px);
    transition: color 0.2s ease-in-out;

    &:hover {
      color: $color-blue;
    }

    &._active {
      color: $color-blue;
    }
  }

  &:hover {
    color: $color-blue;
    background: $color-white;
  }

  &__triangle {
    position: relative;
    padding-right: 3px;

    &:after {
      content: "";
      position: absolute;
      top: calc(50% - 1px);
      right: 1px;
      width: 0;
      height: 0;
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
      border-top: 4px solid $color-grey-7;
    }
  }
}

.menuInner {
  margin-top: 53px;
  border-radius: 0 0 4px 4px;
  border: 1px solid $color-light-grey;
  box-shadow: none;
}

.menuItem {
  height: 45px;
  transition: background 0.2s ease-in-out;

  > a {
    color: $color-black-6;
    text-decoration: none;
  }

  &__text {
    font-size: 14px;
    font-weight: 400;
  }

  &__logOut {
    font-size: 14px !important;
    font-weight: 400;
    color: $color-black-6;
    transition: none;
    padding: 0;
    justify-content: flex-start;

    &:hover {
      color: $color-white;
    }
  }

  &__ref {
    color: $color-black-6;
    text-decoration: none;
  }

  &:hover {
    color: $color-white;
    background: linear-gradient(
      312deg,
      $color-blue-2 -59.61%,
      $color-blue-3 76.32%
    );
  }
}
