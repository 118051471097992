@import "@causevest/ui-kit/variables";

.wrapper {
  position: relative;
  width: 100%;
  background: $color-white;
  overflow: hidden;
  box-shadow: 0 10px 20px 0 rgba(5, 29, 60, 0.03);
  border-radius: 7px;
}

.top {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid $color-light-grey;

  &._tabs {
    padding: 5px 0 0;
  }

  &._decorated {
    position: relative;
    z-index: 2;
  }
}

.title {
  color: $color-black-6;
  font-size: 18px;
  font-weight: 400;
}

.inner {
  padding: 15px;

  &._decorated {
    position: relative;
    z-index: 2;
  }
}

.palm {
  position: absolute;
  z-index: 1;
  right: -30px;

  &._top {
    transform: rotate(-180deg);
    top: -75px;
  }

  &._bottom {
    bottom: -75px;
  }
}
