@import "../../../../../../../node_modules/@causevest/ui-kit/variables";

.wrapper {
  display: flex;
  gap: 30px;
  margin-top: 35px;
  margin-bottom: 35px;
}

.aside {
  gap: 30px;

  &__createBtn {
    &._mobile {
      display: none;
    }

    &._desktop {
      display: block;
    }
  }
}

.main {
  width: 100%;
  gap: 30px;
}

.title {
  color: $color-black-6;
  font-size: 24px;
  font-weight: 300;
}

@media (max-width: 1100px) {
  .wrapper {
    flex-direction: column;
    gap: 25px;
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .aside, .main {
    gap: 25px;
  }

  .aside {
    order: 2;
    width: 100%;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;

    > *:not(.createBtn) {
      width: 48%;
    }

    &__createBtn {
      &._mobile {
        display: block;
      }

      &._desktop {
        display: none;
      }
    }
  }
}

@media (max-width: $sm) {
  .wrapper {
    margin-top: 15px;
    margin-bottom: 15px;
    gap: 15px;
  }

  .aside, .main {
    gap: 15px;
  }

  .aside > *:not(.createBtn) {
    width: 100%;
  }
}
