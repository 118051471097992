@import "@causevest/ui-kit/variables";

.wrapper {
  gap: 50px;
}

.title {
  font-size: 20px;
}

.description {
  font-size: 14px;
  color: $color-grey-1;
  width: 150px;
}

.item {
  gap: 5px;
}

.divider {
  width: 1px;
  min-width: 1px;
  height: 32px;
  background: $color-light-grey;
}

@media (max-width: $md) {
  .wrapper {
    overflow-x: auto;
  }
}
