@import "../../../../../../../node_modules/@causevest/ui-kit/variables";

.title {
  text-align: center;
  font-size: 24px;
  font-weight: 300;
  margin-bottom: 20px;
}

.inner {
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.item {
  gap: 20px;
  padding: 0 20px;
  width: 50%;

  &:first-child {
    border-right: 1px solid $color-light-grey;
  }
}

.img {
  margin: 0 auto;
}

.hint {
  color: $color-grey-7;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  min-height: 65px;
}

.submitBtn {
  width: 250px;
  height: 40px;
  margin: 0 auto;
}

.footer {
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 24px 20px;

  > button {
    min-width: auto;
  }
}

@media (max-width: $sm) {
  .title {
    text-align: center;
    font-size: 20px;
  }

  .inner {
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 30px;
  }

  .item {
    width: auto;
  }

  .img {
    display: none;
  }

  .submitBtn {
    width: 100%;
    height: 40px;
    margin: 0 auto;
  }

  .hint {
    width: auto;
  }
}
