@import "../../../../../../node_modules/@causevest/ui-kit/variables";

.funds {
  margin-top: 20px;

  &__count {
    font-size: 14px;
  }

  &__bar {
    position: relative;
    width: 100%;
    height: 8px;
    border-radius: 8px;
    background: $color-grey-5;
  }

  &__active {
    position: absolute;
    top: 0;
    left: 0;
    height: 8px;
    border-radius: 8px;
    margin-top: 0;
    background: linear-gradient(
      312deg,
      $color-blue-2 -59.61%,
      $color-blue-3 73.4%
    );
  }
}

.description {
  @include multilineEllipsis(3, 100%);
  color: $color-grey-7;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  margin: 25px 0;
}

.rate {
  color: $color-black-6;
  font-size: 14px;
  font-weight: 500;
}

.supportBtn {
  margin-top: 25px;

  &:hover {
    background: $color-white;
  }
}
