@import "@causevest/ui-kit/variables";

.wrapper {
  &._full {
    width: 100%;
  }
}

.inner {
  &._full {
    width: 100%;
  }
}

.item {
  padding: 7.3px;

  &._full {
    width: 50%;
    height: 40px;
    padding: 0 20px;
  }

  &._disabled {
    pointer-events: none;
  }
}
