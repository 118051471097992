@import "../../../../../../../node_modules/@causevest/ui-kit/variables";

.wrapper {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
}

.block {
  gap: 10px;

  a {
    color: $color-blue;
    font-size: 12px;
    white-space: nowrap;
  }
}

.date {
  font-size: 12px;
  color: $color-grey-1;
}

.title {
  font-size: 12px;
  white-space: nowrap;
}

.avatar {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  overflow: hidden;
}

.footer {
  margin-top: 30px;

  &__btn {
    width: 120px;
    height: 35px;
  }
}

@media (max-width: $sm) {
  .wrapper {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 5px;
  }

  .avatar {
    order: 1;
  }

  .title {
    order: 2;
    @include textEllipsis(100%);
  }

  .block a {
    order: 2;
  }
}
