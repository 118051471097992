@import "../../../../../../../node_modules/@causevest/ui-kit/variables";

.wrapper {
  position: relative;
  overflow: hidden;
  border-radius: 7px;
  max-width: 100%;
  width: calc(100vw - 280px);
}

.feed {
  background: $color-white;
  padding: 20px;
  max-width: 1024px;
}

.creatorPic {
  background: $color-white;

  &__inner {
    height: 300px;
    position: relative;
  }
}

.campaign {
  &__pic {
    position: relative;
    width: 100%;
    height: 260px;
    overflow: hidden;
    margin-top: 30px;
  }

  &__description {
    font-size: 16px;
    line-height: 22px;
    word-break: break-word;
    white-space: pre-line;
  }
}

@media (max-width: $sm) {
  .wrapper {
    width: 100%;
  }

  .feed {
    max-width: 100%;
    padding: 15px;
  }

  .campaign {
    &__description {
      font-size: 14px;
      line-height: 20px;
    }

    &__pic {
      height: 180px;
      margin-top: 20px;
    }
  }
}
