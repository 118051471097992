@import "../../../../../../../node_modules/@causevest/ui-kit/variables";

.title {
  color: $color-black-6;
  font-size: 22px;
  font-weight: 300;
  white-space: nowrap;
}

.btn {
  width: 100%;
  height: 35px;
  margin-top: 10px;
}
