@import "../../../../../../../node_modules/@causevest/ui-kit/variables";

.questionBtn {
  &__btn {
    width: 123px;
    height: 32px;
    background: transparent;

    &:hover {
      background: transparent;
    }
  }
}

.questions {
  gap: 30px;

  &__item {
    gap: 10px;
  }
}

.title {
  color: $color-black-6;
  font-size: 18px;
  font-weight: 500;
}

.description {
  margin-top: 10px;
  color: $color-grey-7;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}
