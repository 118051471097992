@import "@causevest/ui-kit/variables";

.wrapper {
  padding: 0 !important;
}

.item {
  padding: 20px 15px;
  gap: 10px;

  &._second {
    border: 1px solid $color-light-grey;
    border-left: none;
    border-right: none;
  }
}

.counter {
  font-size: 24px;
}

.title {
  font-size: 16px;
  white-space: nowrap;
}