@import "../../../../../../../node_modules/@causevest/ui-kit/variables";

.commonGap {
  gap: 20px;
}

.menu {
  margin-top: 20px;

  &__inner {
    margin-left: -15px;
    width: calc(100% + 30px);
    border-top: 1px solid $color-light-grey;
    padding: 25px 30px 0 30px;
  }

  &__columns {
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 60px;
  }

  &__block {
    width: 48%;
    height: 100%;

    &._short {
      width: 250px;
    }
  }
}

.statistic {
  &__columns {
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 60px;
  }

  &__block {
    width: 48%;
    height: 100%;

    &._short {
      width: 250px;
    }
  }
}

@media (max-width: $sm) {
  .commonGap {
    gap: 15px;
  }

  .menu {
    overflow: hidden;
  }

  .statistic {
    &__columns {
      flex-direction: column;
      gap: 30px;
    }

    &__block {
      width: 100%;
    }
  }
}
