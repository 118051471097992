@import "@causevest/ui-kit/variables";

.wrapper {
  height: 340px;
  width: 100%;
  background: $color-white;
}

.inner {
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}

.left {
  gap: 22px;
}

.right {
  gap: 15px;

  > h6 {
    font-size: 14px;
    font-weight: 400;
  }
}

.menuList {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
  gap: 30px;

  &__item {
    font-size: 14px;
    white-space: nowrap;

    > a {
      color: #25292F;
      text-decoration: none;
      transition: color 0.2s ease-in-out;

      &:hover {
        color: #125cd5;
      }
    }
  }
}

.info {
  font-size: 14px;

  a {
    color: #606060;
    text-decoration: none;
  }
}

.social {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  list-style: none;
  padding: 0;
  margin: 0;

  a {
    width: 36px;
    height: 36px;
  }
}

@media (max-width: $sm) {
  .wrapper {
    height: auto;
    padding: 35px 16px;
  }

  .left {
    gap: 22px;
  }

  .inner {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .menuList {
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 20px;
  }
}