@import "../../../../../../../node_modules/@causevest/ui-kit/variables";

.titleInner {
  border-bottom: 1px solid $color-light-grey;
  padding: 16px;
}

.subtitle {
  text-align: center;
  color: $color-grey-7;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}

.title {
  color: $color-grey-7;
  text-align: center;
  font-size: 24px;
  font-weight: 300;
  margin-bottom: 0;
}

.hint {
  color: $color-grey-7;
  font-size: 12px;
  font-weight: 300;
  text-align: center;

  &._noSteps {
    margin-top: 20px;
  }
}

.steps {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 15px;
  margin: 20px auto;
}

.step {
  width: 20px;
  height: 5px;
  border-radius: 5px;
  background: $color-black-6;
  box-shadow: 0 2px 4px rgba(13, 58, 115, 0.1);

  &._active {
    background: linear-gradient(309deg, $color-blue-2 -20.25%, $color-blue-3 109.55%);
  }
}

@media (max-width: $sm) {
  .subtitle {
    text-align: center;
    color: $color-grey-7;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
  }
}


