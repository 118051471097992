@import "../../../../../../node_modules/@causevest/ui-kit/variables";

.title {
  font-size: 24px;
  font-weight: 300;
}

.description {
  line-height: 22px;
  margin-top: 10px;
}

.divider {
  width: 1px;
  min-width: 1px;
  height: 32px;
  background: $color-light-grey;
}

.btn {
  padding: 0;

  svg {
    font-size: 18px;
  }

  p {
    font-size: 14px;
    font-weight: 500;
    text-decoration: underline;
  }

  &:hover {
    background: transparent;
  }
}

@media (max-width: $md) {
  .wrapper {
    overflow-x: auto;
    gap: 30px;
  }

  .item {
    min-width: 150px;
    align-items: flex-start;
  }

  .description {
    font-size: 14px;
    line-height: 18px;
    margin-top: 10px;
  }

  .btn p {
    font-size: 12px;
  }
}