@import "../../../../../../../node_modules/@causevest/ui-kit/variables";

.heading {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $color-light-grey;
  padding: 16px 24px;
  gap: 20px;

  &__title {
    font-size: 24px;
    font-weight: 400;
  }
}

.inner {
  margin: 20px 0;
  gap: 20px;
}

.item {
  flex-direction: row;
  align-items: center;
  border: 1px solid $color-light-grey;
  padding: 10px 15px;
  border-radius: 4px;
  gap: 60px;
  justify-content: space-between;

  &__heading {
    gap: 20px;
  }
}

.title {
  margin-top: 0;
  font-size: 20px;
  font-weight: 500;
}

.description {
  @include multilineEllipsis(2, 300px);
  font-size: 14px;
  font-weight: 400;
}

.location {
  &__heading {
    font-size: 12px;
    color: $color-grey-7;
  }

  &__place {
    font-size: 14px;
    font-weight: 400;
  }
}

.support {
  white-space: nowrap;
  text-decoration: underline;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    background: transparent;
    opacity: 0.6;
  }
}

@media(max-width: $sm) {
  .heading {
    flex-wrap: wrap;
    gap: 20px;
  }

  .item {
    flex-wrap: wrap;
    gap: 20px;
  }
}
