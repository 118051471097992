@import "@causevest/ui-kit/variables";

.title {
  border-bottom: 1px solid $color-light-grey;

  &._centered {
    border-bottom: none;
  }

  &__inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    > h1 {
      font-size: 24px;
    }

    &._centered {
      display: block;
      margin-top: 20px;
      border-bottom: none;
      padding-bottom: 10px;
      text-align: center;
      font-weight: 300;

      .close {
        position: absolute;
        top: 10px;
        right: 10px;
      }
    }
  }
}
