@import "@causevest/ui-kit/variables";

.googleBtn {
  display: flex;
  align-items: center;
  gap: 8px;
  background: $color-blue-1;
  font-size: 14px;
  color: $color-white;
  padding: 10px 20px;
  box-shadow: 0 7px 20px 0 rgba(15, 105, 220, 0.20);
  transition: box-shadow 0.2s ease-in-out;

  &:hover {
    box-shadow: none;
    background: $color-blue-1;
  }

  &__icon {
    display: flex;
    min-width: 16px;
    width: 16px;
    height: 16px;
    background: $color-white;

    > img {
      margin: auto;
    }
  }
}