@import "../../../../../../../node_modules/@causevest/ui-kit/variables";

.wrapper {
  &._isRaiser {
    flex-direction: row;
    justify-content: space-between;
    gap: 30px;
  }
}

.bottomBtns {
  flex-direction: row;
  align-items: center;
  margin-top: 25px;
  gap: 30px;

  &__btn {
    height: 35px;
    font-size: 14px;

    &._secondary {
      height: 35px;
      background: transparent;
      font-size: 14px;
    }

    &._isRaiser {
      height: 35px;
      font-size: 14px;
    }
  }
}

.secondaryInner {
  gap: 5px;

  &__divider {
    width: 1px;
    height: 20px;
    background: rgba(15, 105, 220, 0.5);
    margin: 0 5px;
  }

  &__count {
    color: $color-grey-3;
  }
}

@media (max-width: $sm) {
  .wrapper {
    &._isRaiser {
      flex-direction: column;
      justify-content: flex-start;
      gap: 20px;
    }
  }

  .bottomBtns {
    &._isRaiser {
      margin: 0;
    }
  }
}

@media (max-width: 400px) {
  .bottomBtns {
    flex-direction: column;
    gap: 15px;

    &__btn {
      &:first-child,
      &:last-child {
        width: 100%;
      }
    }
  }
}
