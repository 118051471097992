@import "@causevest/ui-kit/variables";

.timing {
  color: $color-black-6;
  font-size: 12px;
  font-weight: 400;

  &__icon {
    height: 12px;
  }
}