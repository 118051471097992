@import "../../../../../../../node_modules/@causevest/ui-kit/variables";

.heading {
  padding: 20px 24px;
  border-bottom: 1px solid $color-light-grey;
}

.title {
  font-size: 24px;
  font-weight: 300;
}

.wrapper {
  align-items: center;
  margin-top: 24px;
  gap: 24px;
}

.inner {
  flex-direction: row;
  gap: 38px;
}

.tier {
  &__wrapper {
    gap: 20px;
  }

  &__heading {
    flex-direction: row;
    gap: 20px;
  }
}

.info {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  &__donation {
    color: $color-blue;
  }

  &__title {
    font-size: 18px;
    font-weight: 300;
  }
}

.payment {
  width: 100%;
  gap: 20px;

  &__title {
    text-align: center;
    font-size: 18px;
    font-weight: 300;
  }

  &._isLoading {
    overflow: hidden;
    height: 0;
    visibility: hidden;
  }
}

.footer {
  justify-content: space-between;
  width: 100%;
  border-top: 1px solid $color-light-grey;
  padding: 20px 24px;
}

.submitBtn {
  width: 120px;
  height: 33px;
}

@media(max-width: $sm) {
  .inner {
    flex-direction: column;
    align-items: flex-start;
  }

  .info {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
