@import "@causevest/ui-kit/variables";

.wrapper {
  &._isAdaptive {
    width: 100%;
  }
}

.input {
  width: 768px;
  height: 44px;
  background: $color-white;
  overflow: hidden;
  border-radius: 5px;
  border: 1px solid $color-light-grey;

  &._isMobile {
    width: 768px;
    border: 1px solid $color-black-3;
  }

  &._isAdaptive {
    width: 100%;
  }

  > * {
    height: 41px;
    padding-right: 4px;

    fieldset {
      top: 0;
      height: 41px;
      padding: 0;
      border-color: transparent !important;
    }

    &:hover {
      fieldset {
        border-color: transparent !important;
      }
    }
  }

  input {
    padding: 0 0 0 15px !important;
    height: 44px;
  }
}

.button {
  width: 130px;
  height: 34px;
}

@media (max-width: $md) {
  .input {
    width: calc(100vw - 80px);
    margin: 0 auto;

    &._isMobile {
      width: calc(100vw - 80px);

      input {
        height: 35px;
        padding: 0 50px 0 15px;
      }
    }
  }

  .button {
    &._isMobile {
      min-width: 40px;
      width: 40px;
    }
  }
}

@media (max-width: $sm) {
  .button {
    min-width: 80px;
    width: 80px;
  }

  .input {
    input {
      padding: 0 90px 0 15px;
      height: 44px;
    }
  }
}

@media (max-width: 540px) {
  .input {
    width: 100%;

    &._isMobile {
      width: calc(100vw - 80px);
    }
  }

  .wrapper {
    padding: 0 20px;

    &._isMobile, &._isAdaptive {
      padding: 0;
    }
  }
}
