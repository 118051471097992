@import "../../../../../../../node_modules/@causevest/ui-kit/variables";

.createBtn {
  height: 40px;
  padding: 0 20px;
}

@media (max-width: 1100px) {
  .createBtn {
    width: 100%;
  }
}
