@import "../../../../../../../node_modules/@causevest/ui-kit/variables";

.avatar {
  position: relative;

  &__verified {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    background: $color-white;
    border-radius: 50%;
    padding: 4px;

    img {
      margin: auto;
    }
  }

  &__edit {
    display: flex;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 2px;
    border-radius: 50% !important;
    min-width: 25px;
    width: 25px;
    height: 25px;
    border: 1px solid $color-white;

    > * {
      margin: auto;
      width: 19px;
      height: 17px;
    }
  }
}
