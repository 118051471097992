@import "../../../../../../../../node_modules/@causevest/ui-kit/variables";

.mainImg {
  width: calc(100% + 30px);
  height: 120px;
  top: -15px;
  left: -15px;
  overflow: hidden;
  border-radius: 7px 7px 0 0;
}

.wrapper {
  gap: 20px;
}

.inner {
  flex-direction: row;
  gap: 30px;
}

.aside {
  align-items: flex-start;
  gap: 20px;
}

.statistic {
  gap: 15px;

  p {
    font-size: 14px;
  }

  &__title {
    font-weight: 500;
  }
}

.info {
  gap: 30px;
}

@media (max-width: $sm) {
  .aside {
    order: 2;
  }

  .statistic {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 30px;
  }

  .inner {
    flex-direction: column;
    gap: 20px;
  }
}
