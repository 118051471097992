@import "../../../../../../node_modules/@causevest/ui-kit/variables";

.wrapper {
  gap: 30px;
}

.image {
  border-radius: 4px;
  overflow: hidden;
  width: 100%;
  height: 184px;
  margin: 5px 0 20px;

  &__inner {
    width: 100%;
    height: 100%;
  }

  &._donated {
    position: relative;
    height: 78px;
  }
}

.amount {
  position: absolute;
  bottom: 0;
  right: 8px;
  color: $color-white;
  font-size: 24px;
  font-weight: 300;
}

.title {
  color: $color-black-6;
  font-size: 20px;
  font-weight: 500;
}

.description {
  color: $color-grey-7;
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
}
