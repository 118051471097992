@import "@causevest/ui-kit/variables";

.wrapper {
  background: $color-white;
  overflow: hidden;
}

.title {
  text-align: center;
  font-size: 32px;
}

.aside {
  min-width: 220px;
}

.campaigns {
  &__wrapper {
    background: $color-bg-blue;
    padding: 30px 0;
  }
}

.category {
  justify-content: flex-start;
  font-size: 14px !important;
  color: $color-black-6;
  border-radius: 0;
  margin-left: -15px;
  width: calc(100% + 30px);
  padding: 8px 15px;
  transition: color 0.2s linear;

  &._active {
    color: $color-blue;
    background: $color-bg-blue;
  }
}

.campaignsList {
  flex-wrap: nowrap;
  gap: 30px;

  &__inner {
    width: 100%;
    max-width: calc(100% - 250px);
    gap: 30px;
  }
}

.pagination {
  width: 100%;

  &__inner {
    margin: auto;
    background-color: $color-white;
    border-radius: 24px;
    padding: 6px 8px;
    box-shadow: 0 10px 20px 0 rgba(5, 29, 60, 0.03);
  }

  &._flat {
    .pagination__inner {
      box-shadow: none;
    }
  }
}

.search {
  display: flex;
  padding: 25px 0;

  &__inner {
    margin: auto;
  }
}

.pagination {
  &._pMobile {
    display: none;
  }
}

@media (max-width: 920px) {
  .aside {
    display: none;
  }

  .campaignsList__inner {
    max-width: 100%;
  }
}

@media (max-width: $sm) {
  .campaignsList__inner {
    gap: 20px;
  }
}

@media (max-width: 450px) {
  .pagination {
    &._pDesktop {
      display: none;
    }

    &._pMobile {
      display: block;
    }
  }
}
